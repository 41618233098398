import React from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";

export default function setNameField(props) {
  const {
    setFirstName,
    setLastName,
    setPhone,
    firstname,
    phone,
    lastname
  } = props;
  return (
    <div>
      <div className="biggerThan600">
        <Grid container>
          <Grid item sm={5}>
            <TextField
              label="First Name"
              name="firstname"
              value={firstname}
              onChange={setFirstName}
              error={firstname.length === 0}
              fullWidth
            />
          </Grid>
          <Grid item sm={2} />
          <Grid item sm={5}>
            <TextField
              label="Last Name"
              name="lastname"
              value={lastname}
              onChange={setLastName}
              error={lastname.length === 0}
              fullWidth
            />
          </Grid>
        </Grid>
      </div>
      <div className="mobile">
        <TextField
          label="First Name"
          name="firstname"
          value={firstname}
          onChange={setFirstName}
          error={firstname.length === 0}
          fullWidth
        />
        <br />
        <br />
        <br />
        <TextField
          label="Last Name"
          name="lastname"
          value={lastname}
          onChange={setLastName}
          error={lastname.length === 0}
          fullWidth
        />
      </div>
      <br />
      <br />
      <TextField
        label="Phone"
        name="phone"
        value={phone}
        onChange={setPhone}
        error={phone.length < 10}
        helperText="Area Code and Number"
        fullWidth
      />
    </div>
  );
}

setNameField.propTypes = {
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  phone: PropTypes.string,
  setPhone: PropTypes.func.isRequired,
  setFirstName: PropTypes.func.isRequired,
  setLastName: PropTypes.func.isRequired
};

setNameField.defaultProps = {
  firstname: "",
  lastname: "",
  phone: ""
};
