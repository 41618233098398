import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";

export default function setTimeOrderFor(props) {
  const { timeOrderFor, customTime, handleChange, setCustomTime } = props;
  return (
    <div style={{ overflowX: "hidden" }}>
      <FormLabel component="legend" style={{ textAlign: "left" }}>
        Choose Order Time
      </FormLabel>
      <RadioGroup
        aria-label="What Time Would You Like The Order?"
        name="What Time Would You Like The Order?"
        value={timeOrderFor}
        onChange={handleChange}
      >
        <FormControlLabel
          value="As Soon As Possible"
          control={<Radio disableRipple color="primary" />}
          label="As Soon As Possible"
        />
        <FormControlLabel
          value="Custom Time"
          control={<Radio disableRipple />}
          label="Custom Time"
        />
      </RadioGroup>
      {timeOrderFor === "Custom Time" && (
        <TextField
          fullWidth
          label="Input your custom time"
          value={customTime}
          onChange={setCustomTime}
          error={customTime.length === 0}
        />
      )}
      <br />
    </div>
  );
}

setTimeOrderFor.propTypes = {
  timeOrderFor: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  setCustomTime: PropTypes.func.isRequired,
  customTime: PropTypes.string
};

setTimeOrderFor.defaultProps = {
  customTime: null
};
