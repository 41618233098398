import React from "react";
import Radio from "@material-ui/core/Radio";
import Grid from "@material-ui/core/Grid";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import PropTypes from "prop-types";
import CreditCardInput from "react-credit-card-input";
import { useReducer } from "react";

export default function choosePaymentMethod(props) {
  const {
    handleChange,
    paymentMethod,
    ccNumber,
    expDate,
    CVC,
    handleCardNumberChange,
    handleCardExpireChange,
    handleCardCVCChange,
    orderType,
    subtotal,
  } = props;

  function buildCashLabel() {
    if (subtotal * 1.07 >= 50) {
      return "Cash (Eligible for 10 free Krab Rangoons)";
    }
    if (subtotal * 1.07 >= 25) {
      return "Cash (Eligible for 5 free Krab Rangoons)";
    }
    return "Cash";
  }

  return (
    <div style={{ overflowX: "hidden" }}>
      <Grid container justify="center">
        <Grid item xs={9} sm={8}>
          {paymentMethod.length > 0 ? (
            <FormLabel component="legend" style={{ textAlign: "left" }}>
              Choose Payment Method
            </FormLabel>
          ) : (
            <FormLabel
              component="legend"
              style={{ textAlign: "left", color: "#f44336" }}
            >
              Choose Payment Method
            </FormLabel>
          )}
          <div style={{ textAlign: "left", marginTop: 8 }}>
            Pay with cash on orders over $25, get 5 free Krab Rangoons; <br />
            for orders over $50, get 10 free Krab Rangoons.
          </div>
          <RadioGroup
            aria-label="Choose Payment Method"
            name="Choose Payment Method"
            value={paymentMethod}
            onChange={handleChange}
          >
            <FormControlLabel
              value="Credit"
              control={<Radio disableRipple />}
              label="Card"
            />
            <FormControlLabel
              value="Cash"
              control={<Radio disableRipple color="primary" />}
              label={buildCashLabel()}
            />
          </RadioGroup>
        </Grid>
      </Grid>
      {paymentMethod === "Credit" && (
        <div>
          <Grid container justify="center">
            <Grid item xs={11} sm={8}>
              <CreditCardInput
                cardNumberInputProps={{
                  value: ccNumber,
                  onChange: handleCardNumberChange,
                }}
                cardExpiryInputProps={{
                  value: expDate,
                  onChange: handleCardExpireChange,
                }}
                cardCVCInputProps={{
                  value: CVC,
                  onChange: handleCardCVCChange,
                }}
                fieldStyle={{ border: "1px solid black" }}
                invalidStyle={{ border: "1px solid black" }}
              />
            </Grid>
          </Grid>
          <br />
        </div>
      )}
    </div>
  );
}

choosePaymentMethod.propTypes = {
  paymentMethod: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  handleCardNumberChange: PropTypes.func.isRequired,
  handleCardExpireChange: PropTypes.func.isRequired,
  handleCardCVCChange: PropTypes.func.isRequired,
  orderType: PropTypes.string.isRequired,
  subtotal: PropTypes.number.isRequired,
};

choosePaymentMethod.defaultProps = {
  paymentMethod: "Credit",
};
