import React from "react";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";

export default function setAddress(props) {
  const { zip, handleChange } = props;
  return (
    <div>
      <TextField
        fullWidth
        label="Zip"
        value={zip}
        onChange={handleChange}
        error={zip.length < 5}
      />
    </div>
  );
}

setAddress.propTypes = {
  zip: PropTypes.string,
  handleChange: PropTypes.func.isRequired
};

setAddress.defaultProps = {
  zip: ""
};
