import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { navigate } from "gatsby";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../state/mapStateAndDispatch/customerInfo";

import "react-toastify/dist/ReactToastify.min.css";
import "../../styles/buttons.css";

class cartOrReview extends Component {
  toReviewDelivery = () => {
    if (this.basicCheck() === true) {
      const { zip, address } = this.props;
      if (zip.length >= 5 && address.length >= 5) {
        const origin = `${address}, ${zip}`;
        const destination = "3946 4th St N, St. Petersburg, FL 33702";
        const service = new google.maps.DistanceMatrixService();
        service.getDistanceMatrix(
          {
            origins: [origin],
            destinations: [destination],
            travelMode: "DRIVING",
            unitSystem: google.maps.UnitSystem.IMPERIAL,
          },
          res => {
            const dist = parseFloat(
              res.rows[0].elements[0].distance.text
                .split(" ")[0]
                .replace(/,/g, "")
            );

            if (dist <= 3.5) {
              navigate("/review");
            } else {
              toast.error(`Sorry, you are outside of our delivery range.`, {
                position: toast.POSITION.BOTTOM_RIGHT,
                draggablePercent: 66,
              });
            }
          }
        );
      } else {
        toast.error(`Please check the form for errors!`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          draggablePercent: 66,
        });
      }
    } else {
      toast.error(`Please check the form for errors!`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        draggablePercent: 66,
      });
    }
  };

  toCart = () => {
    navigate("/cart");
  };

  toReviewPickup = () => {
    if (this.basicCheck() === true) {
      navigate("/review");
    } else {
      toast.error(this.basicCheck, {
        position: toast.POSITION.BOTTOM_RIGHT,
        draggablePercent: 66,
      });
    }
  };

  basicCheck = () => {
    const {
      firstname,
      phone,
      paymentMethod,
      ccNumber,
      expDate,
      CVC,
    } = this.props;
    if (firstname.length === 0) {
      return "The name field is blank.";
    }
    if (phone.length < 10) {
      return "The phone number is too short, perhaps you forgot the Area Code?";
    }
    if (!paymentMethod) {
      return "Please select a payment method.";
    }
    if (paymentMethod === "Credit") {
      if (!ccNumber || ccNumber.length < 14) {
        return "Please input your credit card number";
      }
      if (!CVC) {
        return "Please enter the 3 digit code of your card.";
      }
      if (!expDate) {
        return "Please enter the expiration date of your card.";
      }
    }
    return true;
  };

  render() {
    const { orderType } = this.props;
    return (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "91.9%",
            margin: "auto",
          }}
        >
          <a
            className="redButton"
            role="button"
            tabIndex={0}
            onClick={this.toCart}
            onKeyUp={this.toCart}
          >
            {" "}
            Cart{" "}
          </a>
          {orderType === "pickup" ? (
            <a
              className="blueButton"
              role="button"
              tabIndex={0}
              onClick={this.toReviewPickup}
              onKeyUp={this.toReviewPickup}
            >
              Review
            </a>
          ) : (
            <a
              className="blueButton"
              role="button"
              tabIndex={0}
              onClick={this.toReviewDelivery}
              onKeyUp={this.toReviewDelivery}
            >
              Review
            </a>
          )}
        </div>
        <br />
      </div>
    );
  }
}

cartOrReview.propTypes = {
  zip: PropTypes.string,
  address: PropTypes.string,
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  phone: PropTypes.string,
  orderType: PropTypes.string.isRequired,
  paymentMethod: PropTypes.string,
};

cartOrReview.defaultProps = {
  zip: "",
  firstname: "",
  lastname: "",
  phone: "",
  address: "",
  paymentMethod: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(cartOrReview);
