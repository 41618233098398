import React from "react";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";

export default function setAddress(props) {
  const { address, handleChange } = props;
  return (
    <div>
      <TextField
        fullWidth
        label="Address"
        value={address}
        onChange={handleChange}
        error={address.length <= 8}
      />
    </div>
  );
}

setAddress.propTypes = {
  address: PropTypes.string,
  handleChange: PropTypes.func.isRequired
};

setAddress.defaultProps = {
  address: ""
};
