import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Grid from "@material-ui/core/Grid";
import { ToastContainer } from "react-toastify";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../state/mapStateAndDispatch/customerInfo";
import Layout from "../components/layout/layout";
import SetNameAndPhone from "../components/customerInfo/setNameAndPhone";
import ChoosePaymentMethod from "../components/customerInfo/choosePaymentMethod";
import SetTimeOrderFor from "../components/customerInfo/setTimeOrderFor";
import CartOrReview from "../components/customerInfo/cartOrReview";
import SetAddress from "../components/customerInfo/setAddress";
import SetZip from "../components/customerInfo/setZip";
import NoItemsInCart from "../components/layout/noItemsInCart";

class customerinfo extends Component {
  componentDidMount() {
    const { paymentPreferences, orderType } = this.props;
    const { setPaymentMethod } = this.props;
    if (paymentPreferences) {
      if (orderType === "pickup") {
        if (paymentPreferences.pickup !== null) {
          setPaymentMethod(paymentPreferences.pickup);
        }
      } else if (orderType === "delivery") {
        if (paymentPreferences.delivery !== null) {
          setPaymentMethod(paymentPreferences.delivery);
        }
      }
    }
  }

  setFirstName = event => {
    const { setFirstName } = this.props;
    const { value } = event.target;
    setFirstName(value);
  };

  setLastName = event => {
    const { setLastName } = this.props;
    const { value } = event.target;
    setLastName(value);
  };

  setPhone = event => {
    const { setPhone } = this.props;
    const { value } = event.target;
    const onlyNums = value.replace(/[^0-9]/g, "");
    if (value.length <= 10) {
      setPhone(onlyNums);
    }
  };

  setPaymentMethod = event => {
    const { setPaymentMethod } = this.props;
    const val = event.target.value;
    setPaymentMethod(val);
  };

  setTimeOrderFor = event => {
    const { setTimeOrderFor } = this.props;
    const val = event.target.value;
    setTimeOrderFor(val);
  };

  setCustomTime = event => {
    const { setCustomTime } = this.props;
    const val = event.target.value;
    setCustomTime(val);
  };

  handleCardNumberChange = event => {
    const { setNumber } = this.props;
    const ccNumber = event.target.value;
    setNumber(ccNumber);
  };

  handleCardExpireChange = event => {
    const { setExpDate } = this.props;
    const expDate = event.target.value;
    setExpDate(expDate);
  };

  handleCardCVCChange = event => {
    const { setCVC } = this.props;
    const CVC = event.target.value;
    setCVC(CVC);
  };

  setAddress = event => {
    const { setAddress } = this.props;
    setAddress(event.target.value);
  };

  setZip = event => {
    const { setZip } = this.props;
    setZip(event.target.value);
  };

  render() {
    const {
      firstname,
      lastname,
      phone,
      paymentMethod,
      orderType,
      timeOrderFor,
      customTime,
      expDate,
      CVC,
      ccNumber,
      address,
      zip,
      orderLen,
      subtotal,
    } = this.props;
    return (
      <Layout>
        <Helmet>
          <title>Chopstick Express | Customer Information</title>
          <meta charset="utf-8" />
        </Helmet>
        <div style={{ backgroundColor: "rgb(252,252,252)", marginBottom: -8 }}>
          <br />
          <h1>Customer Information</h1>
          <br />
          {orderLen > 0 ? (
            <div>
              <Grid container justify="center">
                <Grid item xs={9} sm={8}>
                  <SetNameAndPhone
                    firstname={firstname}
                    lastname={lastname}
                    phone={phone}
                    setFirstName={this.setFirstName}
                    setLastName={this.setLastName}
                    setPhone={this.setPhone}
                  />
                </Grid>
              </Grid>
              <br />
              <br />
              <ChoosePaymentMethod
                expDate={expDate}
                CVC={CVC}
                ccNumber={ccNumber}
                paymentMethod={paymentMethod}
                handleChange={this.setPaymentMethod}
                handleCardNumberChange={this.handleCardNumberChange}
                handleCardExpireChange={this.handleCardExpireChange}
                handleCardCVCChange={this.handleCardCVCChange}
                orderType={orderType}
                subtotal={subtotal}
              />
              <br />
              <Grid container justify="center">
                <Grid item xs={9} sm={8} lg={8}>
                  <SetTimeOrderFor
                    customTime={customTime}
                    timeOrderFor={timeOrderFor}
                    handleChange={this.setTimeOrderFor}
                    setCustomTime={this.setCustomTime}
                  />
                </Grid>
              </Grid>
              {orderType === "delivery" && (
                <div>
                  <br />
                  <Grid container justify="center">
                    <Grid item xs={5} sm={4}>
                      <SetAddress
                        address={address}
                        handleChange={this.setAddress}
                      />
                    </Grid>
                    <Grid item sm={1} xs={1} />
                    <Grid item xs={3} sm={3}>
                      <SetZip zip={zip} handleChange={this.setZip} />
                    </Grid>
                  </Grid>
                  <br />
                </div>
              )}
              <CartOrReview />
              <br />
            </div>
          ) : (
            <NoItemsInCart />
          )}
        </div>
        <ToastContainer hideProgressBar autoClose={2222} />
      </Layout>
    );
  }
}

customerinfo.propTypes = {
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  phone: PropTypes.string,
  orderType: PropTypes.string.isRequired,
  timeOrderFor: PropTypes.string.isRequired,
  customTime: PropTypes.string,
  paymentMethod: PropTypes.string,
  setPaymentMethod: PropTypes.func.isRequired,
  setTimeOrderFor: PropTypes.func.isRequired,
  setFirstName: PropTypes.func.isRequired,
  setLastName: PropTypes.func.isRequired,
  setPhone: PropTypes.func.isRequired,
  setCustomTime: PropTypes.func.isRequired,
  setNumber: PropTypes.func.isRequired,
  setExpDate: PropTypes.func.isRequired,
  setCVC: PropTypes.func.isRequired,
  setAddress: PropTypes.func.isRequired,
  setZip: PropTypes.func.isRequired,
  ccNumber: PropTypes.string,
  expDate: PropTypes.string,
  CVC: PropTypes.string,
  address: PropTypes.string,
  zip: PropTypes.string,
  orderLen: PropTypes.number.isRequired,
  subtotal: PropTypes.number.isRequired,
};

customerinfo.defaultProps = {
  firstname: "",
  lastname: "",
  customTime: "",
  ccNumber: "",
  expDate: "",
  CVC: "",
  paymentMethod: "",
  phone: "",
  address: "",
  zip: "",
};

export default connect(mapStateToProps, mapDispatchToProps)(customerinfo);
